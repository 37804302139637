import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/shared/layout"
import SEO from "../../components/SEO/SEOPage"
import styled from "@emotion/styled"
import { wrapper1200, breakpoints } from "../../utils/style"
import Breadcrumbs from "../../components/shared/Breadcrumbs"
import MobileLandscape from "../../icons/svg/MobileLandscape"
import Parallax from "../../components/shared/Parallax"

const WrapperDesktop = styled("div")`
  ${wrapper1200}

  .game-iframe {
    width: 100%;
    height: 800px;
  }

  .landscape-icon {
    display: none;
    margin: 100px auto 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin-top: 20px;
    }
  }

  @media (max-width: ${breakpoints.lg}) {
    .game-iframe {
      height: calc(100vw / 1.7);
    }
  }

  @media (max-width: ${breakpoints.md}) {
    &:not(.landscape-mode) {
      .landscape-icon {
        display: flex;
      }
      .game-iframe {
        display: none;
        height: 70vh;
      }
    }

    &.landscape-mode {
      .game-iframe {
        display: block;
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 100;
        top: 0;
        left: 0;
      }
    }
  }
`

const ContentWrapper = styled("div")`
  ${wrapper1200}
  margin: 50px auto;
  ul {
    padding-left: 20px;
    letter-spacing: 0.75px;
    line-height: 160%;
  }

  ol {
    padding-left: 20px;
  }

  h2 {
    margin-bottom: 30px;
  }

  p {
    letter-spacing: 0.75px;
    margin: 15px 0;
  }
`

const PageTemplate = ({ data, pageContext }) => {
  const currentPage = data.wordpressPost
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : currentPage.title + "-" + process.env.OWNER
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : ""
  const breadcrumbs = pageContext.breadcrumbs
  const page_title = pageContext.page_title
  const socialImage = currentPage.featured_media?.localFile?.publicURL

  useEffect(() => {
    window.scrollTo(0, 0)
    let pureMQL = window.matchMedia("(orientation: landscape)")
    const gameWrapper = document.getElementById("game-page-wrap")

    if (pureMQL.matches) {
      gameWrapper.classList.add("landscape-mode")
    }

    pureMQL.addListener(function (m) {
      if (m.matches) {
        gameWrapper.classList.add("landscape-mode")
      } else {
        gameWrapper.classList.remove("landscape-mode")
      }
    })
  })

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        breadcrumbs={breadcrumbs}
        socialImage={socialImage}
      />
      <Parallax />
      <WrapperDesktop id="game-page-wrap">
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
        {currentPage.acf.demo_game_iframe && (
          <div>
            <div className="landscape-icon">
              <MobileLandscape />
              <p>Please rotate your device</p>
            </div>
            <iframe
              src={currentPage.acf.demo_game_iframe}
              frameBorder="0"
              scrolling="no"
              title={page_title}
              className="game-iframe"
            />
          </div>
        )}
      </WrapperDesktop>
      <ContentWrapper>
        <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
      </ContentWrapper>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        demo_game_iframe
      }
      featured_media {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`
