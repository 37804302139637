import React from "react"

const MobileLandscape = (
  {
    width = "69",
    height = "72",
    viewBox = "0 0 69 72"
  }) => (
  <svg width={width}
       height={height}
       viewBox={viewBox}
       xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g fill="#ECC45A" fillRule="nonzero">
        <g>
          <path
            d="M63.443 37c2.789 0 5.057 2.295 5.057 5.112v24.776c0 2.82-2.268 5.112-5.057 5.112H38.5c.872-1.508 1.408-3.235 1.409-5.101l23.534.01h.008l.011-24.797-23.552-.01V37zM29.535 15c2.737 0 4.965 2.287 4.965 5.098v46.804c0 2.812-2.228 5.098-4.965 5.098H5.465C2.729 72 .5 69.714.5 66.902V20.098C.5 17.288 2.728 15 5.466 15zM17.498 64.076c-1.447 0-2.62 1.206-2.62 2.692 0 1.487 1.173 2.694 2.62 2.694 1.452 0 2.626-1.207 2.626-2.694 0-1.486-1.174-2.692-2.626-2.692zm-12.032-44l-.017 41.745h24.094l.01-41.723-24.087-.021zM36.96 0c10.167 0 18.448 8.428 18.634 18.855h3.318c.238 0 .452.147.543.375.09.225.039.486-.128.658l-5.762 5.936c-.23.235-.601.235-.832 0l-5.762-5.936c-.167-.172-.217-.433-.128-.658.092-.228.305-.375.544-.375h3.284c-.185-7.632-6.258-13.784-13.711-13.784-1.36 0-2.461-1.136-2.461-2.537C34.5 1.134 35.601 0 36.96 0z"
            transform="translate(-126 -203) translate(126 203)" />
        </g>
      </g>
    </g>
  </svg>)

export default MobileLandscape